@import "@/assets/styles/variables.scss";

















































































.talent-creative {
  .talent-project {
    margin-bottom: 100px;
  }

  .talent-project-name {
    font-size: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .talent-project-desc {
    font-size: 16px;
    font-weight: 400;
  }
}

.talent-card {
  .media-box {
    img {
      max-width: 100%;
      height: auto;
    }

    iframe {
      width: 100%;
    }
  }
}

