@import "@/assets/styles/variables.scss";



















































































@import "~@/assets/styles/_lists.scss";

.projet-box {

  .project-name {
    font-size: 45px;
    font-weight: 400;
  }

  .project-domains {
    @extend .inline-list;
    @extend .flex-center;
    font-size: 20px;
    font-weight: 600;

    .project-domain {
      margin: 0;

      &:after {
        content: '|';
        margin: 0 20px;
      }

      &:last-of-type:after {
        display: none;
      }
    }
  }

  .project-description {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    max-width: 60%;
    margin: 50px auto 30px;
  }

  .project-medias {
    transform: translateY(-10px);
    .media-box {
      text-align: center;

      iframe {
        min-height: 700px;
        min-width: 100%;
      }
    }
  }

  img {
    max-width: 100%;
    height: 100%;
    margin-bottom: 30px;
  }

  .bottomNavTools {
    margin: 0;
  }
}
