ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 0;
    margin-right: 1em;

    li:empty {
      display: none;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-evenly {
    justify-content: space-between;
    justify-content: space-evenly;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.flex-start {
    justify-content: flex-start;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.flex-center {
    justify-content: center;
  }
}

.inline-list {
  @extend .list;
  flex-direction: row;
  align-items: center;

  .reverse-list {
    flex-direction: row-reverse;
  }
}

.reverse-list {
  flex-direction: column-reverse;
}
