@import "@/assets/styles/variables.scss";










































































































.talent-box {
  text-align: center;

  .logo {
    margin-top: 140px;
  }
}

.talent-page {
  h1 {
    font-size: 45px;
    font-weight: 400;
    margin: 0 0 20px;
    text-transform: initial;
  }
}

.talent-jobs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 50%;
  margin: 30px auto 80px;

  li {
    display: flex;
    align-items: center;
    margin: 0 10px 10px 0;
    border-radius: 100px;
    border: 1px solid black;


    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 400px;
    color: black;
    white-space: nowrap;
  }
}

.is-mobile, .is-tablet {
  .talent-page h1 {
    margin-bottom: 10px;
  }

  .talent-jobs {
    max-width: none;
  }

  .talent-jobs li:after {
    margin: 0 5px;
  }
}

.talent-projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  h1 {

  }
}

.talent-project {
  img {
    max-width: 100%;
    height: auto;
  }
}


.bottomNavTools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 140px;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;

    a {
      font-weight: 800;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  li:not(:last-of-type):after {
    content: '|';
    margin: 0 1em;
  }

  /*&:before {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid #000000;
    border-left: 1px solid #000000;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 0 5px;
  }

  &:after {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid #000000;
    border-left: 1px solid #000000;
    -webkit-transform: rotate(45deg);
    transform: rotate(-135deg);
    margin: 0 5px;
  }*/
}
